// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-blog-components-blog-post-js": () => import("./../../../src/blog/components/blog-post.js" /* webpackChunkName: "component---src-blog-components-blog-post-js" */),
  "component---src-knowledgebase-components-knowledgebase-category-js": () => import("./../../../src/knowledgebase/components/knowledgebase-category.js" /* webpackChunkName: "component---src-knowledgebase-components-knowledgebase-category-js" */),
  "component---src-knowledgebase-components-knowledgebase-post-js": () => import("./../../../src/knowledgebase/components/knowledgebase-post.js" /* webpackChunkName: "component---src-knowledgebase-components-knowledgebase-post-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-advantages-js": () => import("./../../../src/pages/advantages.js" /* webpackChunkName: "component---src-pages-advantages-js" */),
  "component---src-pages-agreement-js": () => import("./../../../src/pages/agreement.js" /* webpackChunkName: "component---src-pages-agreement-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-clients-js": () => import("./../../../src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-documentation-js": () => import("./../../../src/pages/documentation.js" /* webpackChunkName: "component---src-pages-documentation-js" */),
  "component---src-pages-feature-analytics-js": () => import("./../../../src/pages/feature/analytics.js" /* webpackChunkName: "component---src-pages-feature-analytics-js" */),
  "component---src-pages-feature-clients-js": () => import("./../../../src/pages/feature/clients.js" /* webpackChunkName: "component---src-pages-feature-clients-js" */),
  "component---src-pages-feature-index-js": () => import("./../../../src/pages/feature/index.js" /* webpackChunkName: "component---src-pages-feature-index-js" */),
  "component---src-pages-feature-integration-js": () => import("./../../../src/pages/feature/integration.js" /* webpackChunkName: "component---src-pages-feature-integration-js" */),
  "component---src-pages-feature-iptelephony-js": () => import("./../../../src/pages/feature/iptelephony.js" /* webpackChunkName: "component---src-pages-feature-iptelephony-js" */),
  "component---src-pages-feature-messengers-js": () => import("./../../../src/pages/feature/messengers.js" /* webpackChunkName: "component---src-pages-feature-messengers-js" */),
  "component---src-pages-feature-mobile-js": () => import("./../../../src/pages/feature/mobile.js" /* webpackChunkName: "component---src-pages-feature-mobile-js" */),
  "component---src-pages-feature-notifications-js": () => import("./../../../src/pages/feature/notifications.js" /* webpackChunkName: "component---src-pages-feature-notifications-js" */),
  "component---src-pages-feature-onlinecashbox-js": () => import("./../../../src/pages/feature/onlinecashbox.js" /* webpackChunkName: "component---src-pages-feature-onlinecashbox-js" */),
  "component---src-pages-feature-portal-beauty-js": () => import("./../../../src/pages/feature/portal-beauty.js" /* webpackChunkName: "component---src-pages-feature-portal-beauty-js" */),
  "component---src-pages-feature-portal-creativeschool-js": () => import("./../../../src/pages/feature/portal-creativeschool.js" /* webpackChunkName: "component---src-pages-feature-portal-creativeschool-js" */),
  "component---src-pages-feature-portal-education-js": () => import("./../../../src/pages/feature/portal-education.js" /* webpackChunkName: "component---src-pages-feature-portal-education-js" */),
  "component---src-pages-feature-portal-js": () => import("./../../../src/pages/feature/portal.js" /* webpackChunkName: "component---src-pages-feature-portal-js" */),
  "component---src-pages-feature-portal-sports-js": () => import("./../../../src/pages/feature/portal-sports.js" /* webpackChunkName: "component---src-pages-feature-portal-sports-js" */),
  "component---src-pages-feature-subscriptions-beauty-js": () => import("./../../../src/pages/feature/subscriptions-beauty.js" /* webpackChunkName: "component---src-pages-feature-subscriptions-beauty-js" */),
  "component---src-pages-feature-subscriptions-creativeschool-js": () => import("./../../../src/pages/feature/subscriptions-creativeschool.js" /* webpackChunkName: "component---src-pages-feature-subscriptions-creativeschool-js" */),
  "component---src-pages-feature-subscriptions-education-js": () => import("./../../../src/pages/feature/subscriptions-education.js" /* webpackChunkName: "component---src-pages-feature-subscriptions-education-js" */),
  "component---src-pages-feature-subscriptions-js": () => import("./../../../src/pages/feature/subscriptions.js" /* webpackChunkName: "component---src-pages-feature-subscriptions-js" */),
  "component---src-pages-feature-subscriptions-sports-js": () => import("./../../../src/pages/feature/subscriptions-sports.js" /* webpackChunkName: "component---src-pages-feature-subscriptions-sports-js" */),
  "component---src-pages-feature-tasks-js": () => import("./../../../src/pages/feature/tasks.js" /* webpackChunkName: "component---src-pages-feature-tasks-js" */),
  "component---src-pages-feature-wages-js": () => import("./../../../src/pages/feature/wages.js" /* webpackChunkName: "component---src-pages-feature-wages-js" */),
  "component---src-pages-feature-widgets-js": () => import("./../../../src/pages/feature/widgets.js" /* webpackChunkName: "component---src-pages-feature-widgets-js" */),
  "component---src-pages-franchise-js": () => import("./../../../src/pages/franchise.js" /* webpackChunkName: "component---src-pages-franchise-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-kz-js": () => import("./../../../src/pages/index.kz.js" /* webpackChunkName: "component---src-pages-index-kz-js" */),
  "component---src-pages-index-ua-js": () => import("./../../../src/pages/index.ua.js" /* webpackChunkName: "component---src-pages-index-ua-js" */),
  "component---src-pages-knowledgebase-index-js": () => import("./../../../src/pages/knowledgebase/index.js" /* webpackChunkName: "component---src-pages-knowledgebase-index-js" */),
  "component---src-pages-knowledgebase-search-js": () => import("./../../../src/pages/knowledgebase/search.js" /* webpackChunkName: "component---src-pages-knowledgebase-search-js" */),
  "component---src-pages-landing-beauty-js": () => import("./../../../src/pages/landing/beauty.js" /* webpackChunkName: "component---src-pages-landing-beauty-js" */),
  "component---src-pages-landing-businessschool-js": () => import("./../../../src/pages/landing/businessschool.js" /* webpackChunkName: "component---src-pages-landing-businessschool-js" */),
  "component---src-pages-landing-childdev-js": () => import("./../../../src/pages/landing/childdev.js" /* webpackChunkName: "component---src-pages-landing-childdev-js" */),
  "component---src-pages-landing-creativeschool-js": () => import("./../../../src/pages/landing/creativeschool.js" /* webpackChunkName: "component---src-pages-landing-creativeschool-js" */),
  "component---src-pages-landing-crm-dlja-tvorcheskih-shkol-js": () => import("./../../../src/pages/landing/crm-dlja-tvorcheskih-shkol.js" /* webpackChunkName: "component---src-pages-landing-crm-dlja-tvorcheskih-shkol-js" */),
  "component---src-pages-landing-crm-dlya-muzykalnoj-shkoly-js": () => import("./../../../src/pages/landing/crm-dlya-muzykalnoj-shkoly.js" /* webpackChunkName: "component---src-pages-landing-crm-dlya-muzykalnoj-shkoly-js" */),
  "component---src-pages-landing-culinaryschool-js": () => import("./../../../src/pages/landing/culinaryschool.js" /* webpackChunkName: "component---src-pages-landing-culinaryschool-js" */),
  "component---src-pages-landing-dance-js": () => import("./../../../src/pages/landing/dance.js" /* webpackChunkName: "component---src-pages-landing-dance-js" */),
  "component---src-pages-landing-education-js": () => import("./../../../src/pages/landing/education.js" /* webpackChunkName: "component---src-pages-landing-education-js" */),
  "component---src-pages-landing-examsschool-js": () => import("./../../../src/pages/landing/examsschool.js" /* webpackChunkName: "component---src-pages-landing-examsschool-js" */),
  "component---src-pages-landing-football-js": () => import("./../../../src/pages/landing/football.js" /* webpackChunkName: "component---src-pages-landing-football-js" */),
  "component---src-pages-landing-info-js": () => import("./../../../src/pages/landing/info.js" /* webpackChunkName: "component---src-pages-landing-info-js" */),
  "component---src-pages-landing-it-school-js": () => import("./../../../src/pages/landing/IT_school.js" /* webpackChunkName: "component---src-pages-landing-it-school-js" */),
  "component---src-pages-landing-kindergarden-js": () => import("./../../../src/pages/landing/kindergarden.js" /* webpackChunkName: "component---src-pages-landing-kindergarden-js" */),
  "component---src-pages-landing-language-js": () => import("./../../../src/pages/landing/language.js" /* webpackChunkName: "component---src-pages-landing-language-js" */),
  "component---src-pages-landing-registration-js": () => import("./../../../src/pages/landing/registration.js" /* webpackChunkName: "component---src-pages-landing-registration-js" */),
  "component---src-pages-landing-sports-js": () => import("./../../../src/pages/landing/sports.js" /* webpackChunkName: "component---src-pages-landing-sports-js" */),
  "component---src-pages-obnovleniya-paraplan-crm-js": () => import("./../../../src/pages/obnovleniya-paraplan-crm.js" /* webpackChunkName: "component---src-pages-obnovleniya-paraplan-crm-js" */),
  "component---src-pages-obuchenie-po-crm-js": () => import("./../../../src/pages/obuchenie-po-crm.js" /* webpackChunkName: "component---src-pages-obuchenie-po-crm-js" */),
  "component---src-pages-order-success-js": () => import("./../../../src/pages/order/success.js" /* webpackChunkName: "component---src-pages-order-success-js" */),
  "component---src-pages-ourpartners-js": () => import("./../../../src/pages/ourpartners.js" /* webpackChunkName: "component---src-pages-ourpartners-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-payments-js": () => import("./../../../src/pages/payments.js" /* webpackChunkName: "component---src-pages-payments-js" */),
  "component---src-pages-policy-en-js": () => import("./../../../src/pages/policy.en.js" /* webpackChunkName: "component---src-pages-policy-en-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-special-quarantine-offer-js": () => import("./../../../src/pages/special/quarantine-offer.js" /* webpackChunkName: "component---src-pages-special-quarantine-offer-js" */)
}

